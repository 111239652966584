.FABContainer{
  background-image: url("https://storage.googleapis.com/base-lp-assets/whatsapp_FAB.svg");
  background-position: center;
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  height: 100px;
  position: fixed;
  right: 0;
  width: 100px;
  z-index: 999;
}

.FABContainer:hover{
  background-image: url("https://storage.googleapis.com/base-lp-assets/whatsapp_FAB_hover.svg");
}